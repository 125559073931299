
  import type { VastgoedexpertsProduct } from "@/store/modules/vastgoedexperts.store";
  import { Component, Prop, Vue } from "vue-property-decorator";
  import { ImmoSignType } from "@/store/modules/partner.store";
  import { formatDate } from "@/utils/formatters";
  import { Action, Getter } from "vuex-class";
  import { bus } from "@/main";

  @Component({
    components: {
      Spinner: () => import("@/components/general/spinner.vue"),
    },
  })
  export default class DossierPartVgeRequestStatus extends Vue {
    @Getter("vastgoedexperts/all") vastgoedexpertsProducts!: VastgoedexpertsProduct[];

    @Action("vastgoedexperts/readStatus") readStatus!: (payload: { activity_id: number; partner_id: number }) => Promise<VastgoedexpertsProduct[]>;

    @Prop({ required: true }) activityId!: number;

    @Prop({ required: true }) partnerId!: number;

    @Prop({ default: true }) hasOwners!: boolean;

    loading = false;

    formatDate = formatDate;

    ImmoSignType = ImmoSignType;

    matchCount = 0;

    bus = bus;

    async mounted() {
      await this.fetchStatusses();

      this.matchCount = this.getMatchCount();
    }

    get products() {
      return [...this.vastgoedexpertsProducts];
    }

    get partnerVastgoedserviceProducts() {
      return this.products.filter((vgeStatus: VastgoedexpertsProduct) => vgeStatus.activity_id === this.activityId && vgeStatus.partner_id === this.partnerId) ?? [];
    }

    getMatchCount() {
      return (
        this.products && this.products.filter((vgeProduct: VastgoedexpertsProduct) => vgeProduct.activity_id === this.activityId && vgeProduct.partner_id === this.partnerId).length
      );
    }

    updateSignProduct(product: VastgoedexpertsProduct) {
      bus.$emit("show-vge-update-signs", product, this.onServiceRequested);
    }

    onServiceRequested(product: VastgoedexpertsProduct) {
      this.longPollRequest();
    }

    async fetchStatusses() {
      if (this.matchCount > 0) {
        return;
      }

      this.loading = true;

      await this.readStatus({ activity_id: this.activityId, partner_id: this.partnerId });

      this.loading = false;
    }

    async longPollRequest(counter: number = 0) {
      if (counter === 0) {
        this.loading = true;
      }

      await this.readStatus({ activity_id: this.activityId, partner_id: this.partnerId });

      if (this.getMatchCount() > this.matchCount) {
        this.loading = false;
        return;
      }

      if (counter < 11) {
        setTimeout(() => this.longPollRequest(counter + 1), 2000);
      } else {
        this.loading = false;
      }
    }

    generateTooltip(content: string) {
      return { content: content, offset: [0, 12], delay: { show: 500, hide: 100 }, placement: "top" };
    }
  }
